import css from '../../../css/write.module.css'

const WriteTextArea = () => {
  

  return (
    <textarea className={css.textArea} placeholder='직접 적어보아요'>

    </textarea>
  )
}

export default WriteTextArea